import * as React from "react";
import Layout from "../components/layout";
// import * as sections from "../components/sections"
// import Fallback from "../components/fallback"
import { FormattedMessage } from "react-intl";
import LinkTranslated from "../LinkTranslated";
import SimpleLocalize from "../SimpleLocalize";
import Preloader from "../components/preloader";
import { PageBanner } from "../components/sections/PageBanner";
import { PageNavBar } from "../components/sections/pageNavBar";
import { ValuePropsHome } from "../components/sections/ValuePropsHome";
import { ServiceFeatures } from "../components/sections/ServiceFeatures";
import { HowWeWork } from "../components/sections/HowWeWork";
import { PriceTable } from "../components/sections/PriceTable";
import { Cta } from "../components/sections/Cta";
import { ValueProposition } from "../components/sections/ValueProposition";

export default function Storage(props) {
  // useEffect(() => {
  //   const script = document.createElement('script');

  //   script.src = "/js/slick-animation.min.js";
  //   script.async = true;

  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   }
  // }, []);

  return (
    <SimpleLocalize {...props}>
      <Layout>
        <LinkTranslated href="/">
          {/* <FormattedMessage id="header--service-1" /> */}
          {/* <PageBanner textId="header--service-1" imagePath="/img/page-banner.jpg"/> */}
        </LinkTranslated>
        <PageBanner
          textId="header--service-1"
          imagePath="/img/slide1.jpg"
        />
        <PageNavBar
          fromId="header--home"
          currentId="header--service-1"
        ></PageNavBar>
        <ValuePropsHome />

        <section class="service-details-wrapper">
          <div class="container">
            <div class="row" style={{ justifyContent: "center" }}>
              <div class="col-xl-10 col-12 pe-xl-5">
                <div class="service-details-contents">
                  <div class="contents">
                    <h2>
                      <FormattedMessage id="service1--feature-1-title" />
                    </h2>
                    <div class="service-feature-img" style={{display:'flex', justifyContent:'center'}}>
                      <img src="/img/one-stop.png" alt="" style={{width:'100%', maxWidth:'700px'}}/>
                    </div>
                    <p>
                      <FormattedMessage id="service1--feature-1-desc" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div style={{ height: "120px" }} />
          <div class="container">
            <div class="row" style={{ justifyContent: "center" }}>
              <div class="col-xl-10 col-12 pe-xl-5 right">
                <div class="service-details-contents">
                  <div class="contents">
                    <h2>
                      <FormattedMessage id="service1--feature-2-title" />
                    </h2>
                    <div class="service-feature-img"  style={{display:'flex', justifyContent:'center'}}>
                      <img src="/img/secure.png" alt="" style={{width:'100%',maxWidth:'700px'}}/>
                    </div>
                    <p>
                      <FormattedMessage id="service1--feature-2-desc" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div style={{ height: "80px" }} />
        <ServiceFeatures />
        <div style={{ height: "120px" }} />
        <HowWeWork />
        <PriceTable />
        <ValueProposition />
      </Layout>
    </SimpleLocalize>
  );
}
